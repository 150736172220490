@tailwind base;
@tailwind components;
@tailwind utilities;

.marry {
  background: -webkit-linear-gradient(#914c3f, #e3aaae);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  height: calc(100vh / 3) !important;
  padding: 0 20px;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#img1 {
  object-position: 30% 50%;
}

#img2 {
  object-position: 20% 50%;
}

#img6 {
  object-position: 50% 20%;
}

#img7 {
  object-position: 45% 40%;
}

#img8 {
  object-position: 50% 10%;
}

#img9 {
  object-position: 50% 20%;
}
